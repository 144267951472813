import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Some key benefits are its dynamic typing and flexible syntax.`}</p>
    <p>{`Additionally, JavaScript’s asynchronous programming model and support for event-driven programming make it well-suited for building responsive and interactive web applications, although it is also used in server-side applications and other contexts.`}</p>
    <p>{`The growing ecosystem of tools, libraries, and frameworks has made JavaScript a popular choice for many developers.`}</p>
    <h2>{`Frameworks`}</h2>
    <Row mdxType="Row">
      <Column colMd={3} colLg={3} mdxType="Column">
  <ImageCard title="Gatsby" titleColor="dark" actionIcon="arrowRight" iconColor="dark" href="/technologies/frameworks/gatsby/overview/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAEU0lEQVQ4y22Ua0xbZRjHS+sSP/lhMTHzo+O6i7Tn9IpsCl628MFbNNkol7H2nF6A6pgLydSgDG/LXKQUem5cxjoGGzNZiGS4ZDgvRGFxMahBh05nEGXABjpte3rexzzvaQtGm7w9J+/znt/7/J+bwZD+caxo4BmBLo4VjemV3k/bWIG+c6xg4hkxZ80uGP71oxAmbWRFE8+KBq9FMDQ9duL+htLu3UGXwtW7FE/jju7yQ7v67+UoXD/LrTmxBuPTB/x2yei3S4agSynw26VY7ZbIbXdeGNy57frKC8O+rZ0LAYccCbqUTX6bZPDZpCwUFWRdRljQKRsCDrnaa47Gqws6oOX5MzAiXVGnLs6qk2PX1PcjX6iHnxwAtHEWYTHgkJ8IOGSDzyqaMiqzMtGzgEOu2f9gFwScMkx9OJsAIKkbMze1z0e/1ybHZrX567c0AEh9NPxN3GsRgJ51yOU+m4Qwkw5kRCPKDTrlQq85mgg4ZJibXUrenFtVj1QOp2qKIgTlVua2w75tneS4f0S7sxonP07/Ts96LcJcQ2n3RpowVsjBPyNnoZJjVflhmLxwLbE0v5rkGEFFaT6bBH6bRHD5rCLsfeA9aK6IkUuD01poZ0/Ca44CbxXbaFIZ0UR1YzYxAa17h0FNptRXnhlUa7dEUA7hWZFwjAC48N1vlwjKrS7owH2Nt4pom+FZ8W6aaQTWlyi7awo7IBwaVQFAa28c1dy57fgxrAMinMatbnsX8RRH9ac5Svc5RthK61F3VeBQmjsvnBp6d0LToR8gFDJQ+pFFIAcf7ycvP30aMNuHnxogLzzcq6HHvFUsp0mhJWOT9tdt74K+18fVoEshsTc/zkIxGRh8vLC2KEImRmY0QghZWbpDAIAMHfuMxrqxtPsRKhmBoR09ZVi056OTyYVfbmPxkoG3P9Ey8jNQr0UgzRUxreW5Ia3NfS6ViKtaZ9MFUpUfVg+U9+Vn6/DQrpMb67Z1/tZcEQMAUG98t4ixIgPvfPofKEp/dtMx6G0Z1/5ciav1LgU8xdGrva+NG3XJ2OisiEUdxsSM9ny5gtSfvl2g0FNv6Z6GQ6Na5eZ2wNW65yzuYWgSWGpBl3KAt2bKhhVzsBeDTvk+jhEWMMBXLv6wjB/MX78FnuIucvKNyxTadXBMQ8/QdmlwOoGl5bOKX9WXKBu85mi6lxm99bB9Ag65DAsVS+LM8YnlZCL19+Kvq6nQzh5y+qguf2Xpr1T/kctJhPGsuOy3S0U+K2UY9aSw+ujBBsdGDzjkUp4Vf8bM1ZcoEH1pLH7Ue/6P6oKOVOues3QPZfqs4lW/XSrEicNbRVNmNmaB6ClC8bagS7kn6JRf9ZijX1flh1NYUumyiXuKo1NBlxJqeKj7LurZOlh20GYGZGbyYG9jkNvc5zY0PXqiAL3mGKHkxbK+zQCAFxs8NGbrp/r/Te01Yw5mPzvFGX3089lLBVP6qStkxCzoH6KSB3T8T0YwAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Gatsby",
              "title": "Gatsby",
              "src": "/static/99353e5ac9a4344f013bea93f860d25a/36a81/gatsby.png",
              "srcSet": ["/static/99353e5ac9a4344f013bea93f860d25a/7fc1e/gatsby.png 288w", "/static/99353e5ac9a4344f013bea93f860d25a/36a81/gatsby.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      