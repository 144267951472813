import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Phpactor</AnchorLink>
  <AnchorLink mdxType="AnchorLink">PHP CS Fixer</AnchorLink>
  <AnchorLink mdxType="AnchorLink">PHP_CodeSniffer</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Rector</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Symplify</AnchorLink>
    </AnchorLinks>
    <h2>{`Phpactor`}</h2>
    <p><strong parentName="p">{`Installation`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl -sSL https://github.com/phpactor/phpactor/releases/latest/download/phpactor.phar -o phpactor.phar \\
&& chmod +x phpactor.phar
`}</code></pre>
    <p><strong parentName="p">{`Configuration`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://phpactor.readthedocs.io/en/master/reference/configuration.html"
      }}>{`Config documentation`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=phpactor.yml",
        "path": "phpactor.yml"
      }}>{``}</code></pre>
    <p><strong parentName="p">{`Run fixes`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`./phpactor.phar class:transform --transform=add_missing_docblocks_return "src/**/*.php"
`}</code></pre>
    <h2>{`PHP CS Fixer`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/PHP-CS-Fixer/PHP-CS-Fixer"
      }}>{`Source code`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/PHP-CS-Fixer/PHP-CS-Fixer/tree/master#documentation"
      }}>{`Documentation`}</a></p>
    <p><strong parentName="p">{`Installation`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`composer require --dev friendsofphp/php-cs-fixer kubawerlos/php-cs-fixer-custom-fixers
`}</code></pre>
    <p><strong parentName="p">{`Configuration`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/PHP-CS-Fixer/PHP-CS-Fixer/blob/master/doc/config.rst"
      }}>{`Config documentation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/PHP-CS-Fixer/PHP-CS-Fixer/blob/master/doc/rules/index.rst"
      }}>{`Built-in rules`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/PHP-CS-Fixer/PHP-CS-Fixer/blob/master/doc/ruleSets/index.rst"
      }}>{`Built-in rule sets`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/kubawerlos/php-cs-fixer-custom-fixers"
      }}>{`PHP CS Fixer: custom fixers`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-php",
        "metastring": "path=.php-cs-fixer.php",
        "path": ".php-cs-fixer.php"
      }}>{`<?php

$config = new PhpCsFixer\\Config();

$config->setRules([
    '@PSR2'  => true,
    '@PSR12' => true,
]);

return $config;
`}</code></pre>
    <p><strong parentName="p">{`Run fixers`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`vendor/bin/php-cs-fixer fix src
`}</code></pre>
    <h2>{`Rector`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/rectorphp/rector"
      }}>{`Source code`}</a>{`
`}<a parentName="p" {...{
        "href": "https://getrector.com/documentation/"
      }}>{`Documentation`}</a></p>
    <p><strong parentName="p">{`Installation`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`composer require --dev rector/rector
`}</code></pre>
    <p><strong parentName="p">{`Configuration`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://getrector.com/documentation/set-lists"
      }}>{`Config documentation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/rectorphp/rector/blob/-/docs/rector_rules_overview.md"
      }}>{`Built-in rules`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/deprecated-packages/rector-cakephp/blob/-/docs/rector_rules_overview.md"
      }}>{`CakePHP rules`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/rectorphp/rector-doctrine/blob/-/docs/rector_rules_overview.md"
      }}>{`Doctrine rules`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/rectorphp/rector-nette/blob/main/docs/rector_rules_overview.md"
      }}>{`Nette rules`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/rectorphp/rector-phpoffice/blob/main/docs/rector_rules_overview.md"
      }}>{`PHPOffice rules`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/rectorphp/rector-phpunit/blob/main/docs/rector_rules_overview.md"
      }}>{`PHPUnit rules`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/rectorphp/rector-symfony/blob/-/docs/rector_rules_overview.md"
      }}>{`Symfony rules`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-php",
        "metastring": "path=rector.php",
        "path": "rector.php"
      }}>{`<?php

use Rector\\Config\\RectorConfig;
use Rector\\Doctrine\\CodeQuality\\Rector\\Property\\TypedPropertyFromColumnTypeRector;
use Rector\\Doctrine\\CodeQuality\\Rector\\Property\\TypedPropertyFromDoctrineCollectionRector;
use Rector\\Doctrine\\CodeQuality\\Rector\\Property\\TypedPropertyFromToManyRelationTypeRector;
use Rector\\Doctrine\\CodeQuality\\Rector\\Property\\TypedPropertyFromToOneRelationTypeRector;
use Rector\\Set\\ValueObject\\SetList;

return function (RectorConfig $rectorConfig): void {
    $rectorConfig->import(SetList::PHP_82);

    $rectorConfig->rules([
        TypedPropertyFromColumnTypeRector::class,
        TypedPropertyFromDoctrineCollectionRector::class,
        TypedPropertyFromToManyRelationTypeRector::class,
        TypedPropertyFromToOneRelationTypeRector::class,
    ]);

    $rectorConfig->importNames();
    $rectorConfig->importShortClasses(false);
};
`}</code></pre>
    <p><strong parentName="p">{`Run fixers`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`vendor/bin/rector process src
`}</code></pre>
    <h2>{`PHP_CodeSniffer`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/squizlabs/PHP_CodeSniffer"
      }}>{`Source code`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/squizlabs/PHP_CodeSniffer/wiki"
      }}>{`Documentation`}</a></p>
    <p><strong parentName="p">{`Installation`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`composer require --dev squizlabs/php_codesniffer slevomat/coding-standard
`}</code></pre>
    <p><strong parentName="p">{`Configuration`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/squizlabs/PHP_CodeSniffer/wiki/Annotated-Ruleset"
      }}>{`Config documentation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/squizlabs/PHP_CodeSniffer/wiki/Customisable-Sniff-Properties"
      }}>{`Built-in rules`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/slevomat/coding-standard#alphabetical-list-of-sniffs"
      }}>{`Slevomat Coding Standard`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "path=phpcs.xml",
        "path": "phpcs.xml"
      }}>{`<?xml version="1.0"?>
<ruleset>
    <autoload>./vendor/autoload.php</autoload>
    <config name="installed_paths" value="../../slevomat/coding-standard"/>

    <rule ref="PSR2"/>
    <rule ref="PSR12"/>
</ruleset>
`}</code></pre>
    <p><strong parentName="p">{`Run fixers`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`vendor/bin/phpcbf src
`}</code></pre>
    <h2>{`Symplify`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/easy-coding-standard/easy-coding-standard"
      }}>{`Source code`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/easy-coding-standard/easy-coding-standard/blob/main/README.md"
      }}>{`Documentation`}</a></p>
    <p>{`Symplify supports rules and rulesets from several refactoring tools, such as `}<a parentName="p" {...{
        "href": "#php_codesniffer"
      }}>{`PHP_CodeSniffer`}</a>{` and `}<a parentName="p" {...{
        "href": "#php-cs-fixer"
      }}>{`PHP CS Fixer`}</a>{`.`}</p>
    <p><strong parentName="p">{`Installation`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`composer require --dev symplify/coding-standard symplify/easy-coding-standard
`}</code></pre>
    <p><strong parentName="p">{`Configuration`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/symplify/coding-standard/blob/main/docs/rules_overview.md"
      }}>{`Built-in rules`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-php",
        "metastring": "path=ecs.php",
        "path": "ecs.php"
      }}>{`<?php

use PhpCsFixer\\Fixer\\Phpdoc\\NoEmptyPhpdocFixer;
use PhpCsFixer\\Fixer\\Phpdoc\\NoSuperfluousPhpdocTagsFixer;
use PhpCsFixer\\Fixer\\Phpdoc\\PhpdocAlignFixer;
use PhpCsFixer\\Fixer\\Whitespace\\NoExtraBlankLinesFixer;
use PhpCsFixer\\Fixer\\Whitespace\\NoWhitespaceInBlankLineFixer;
use Symplify\\EasyCodingStandard\\Config\\ECSConfig;
use Symplify\\EasyCodingStandard\\ValueObject\\Set\\SetList;

return static function (ECSConfig $ecsConfig): void {
    $ecsConfig->sets([
        SetList::PSR_12,
    ]);

    $ecsConfig->rulesWithConfiguration([
        PhpdocAlignFixer::class => [
            'align' => 'vertical',
        ],
        NoSuperfluousPhpdocTagsFixer::class => [
            'allow_mixed' => true,
        ],
        NoExtraBlankLinesFixer::class => [
            'tokens' => [
                'extra',
            ],
        ],
    ]);

    $ecsConfig->rules([
        NoEmptyPhpdocFixer::class,
        NoWhitespaceInBlankLineFixer::class,
    ]);
};
`}</code></pre>
    <p><strong parentName="p">{`Run fixers`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`vendor/bin/ecs check src --fix
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      