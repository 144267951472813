import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Resources</AnchorLink>
    </AnchorLinks>
    <h2>{`Resources`}</h2>
    <h3>{`Base`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/velero/kustomization.yaml",
        "path": "/base/velero/kustomization.yaml"
      }}>{`---
apiVersion: kustomize.config.k8s.io/v1beta1
kind: Kustomization

namespace: velero

resources:
  - namespace.yaml

generators:
  - velero-chart.yaml

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/velero/namespace.yaml",
        "path": "/base/velero/namespace.yaml"
      }}>{`---
apiVersion: v1
kind: Namespace

metadata:
  name: velero

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/velero/velero-chart.yaml",
        "path": "/base/velero/velero-chart.yaml"
      }}>{`---
apiVersion: builtin
kind: HelmChartInflationGenerator

name: velero

metadata:
  name: velero-helm-chart

repo: https://vmware-tanzu.github.io/helm-charts
releaseName: velero
version: 2.32.1

IncludeCRDs: true

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      