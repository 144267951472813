import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Model`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using Microsoft.AspNetCore.Mvc.RazorPages;

public class IndexModel : PageModel
{
    private readonly CustomerRepository _customerRepository;

    public IndexModel(CustomerRepository customerRepository)
    {
        _customerRepository = customerRepository;
    }

    public List<Customer> Customers { get; set; }

    public void OnGet()
    {
        Customers = _customerRepository.GetCustomers();
    }
}
`}</code></pre>
    <h2>{`View`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-cshtml"
      }}>{`@model (dynamic, RazorPagesContacts.Pages.Customers.IndexModel)
@addTagHelper *, Microsoft.AspNetCore.Mvc.TagHelpers

<!-- Option 1: use ViewData -->
<div>
    <h1>@((MyNamespace.MyModel1)ViewData["Model1"]).Title</h1>
    <p>@((MyNamespace.MyModel1)ViewData["Model1"]).Content</p>
</div>

<div>
    <h1>@((MyNamespace.MyModel2)ViewData["Model2"]).Name</h1>
    <p>@((MyNamespace.MyModel2)ViewData["Model2"]).Description</p>
</div>

<!-- Option 2: use dynamic view model -->
<div>
    <h1>@Model.Item1.MyModel1.Title</h1>
    <p>@Model.Item1.MyModel1.Content</p>
</div>

<div>
    <h1>@Model.Item1.MyModel2.Name</h1>
    <p>@Model.Item1.MyModel2.Description</p>
</div>

<!-- Option 3: access the IndexModel -->
<h1>Contacts home page</h1>

<form method="post">
    <table class="table">
        <thead>
            <th>ID</th>
            <th>Name</th>
            <th></th>
        </thead>
        <tbody>
            @foreach (var contact in Model.Item2.Customer)
            {
                <tr>
                    <td>@contact.Id</td>
                    <td>@contact.Name</td>
                    <td>
                        <a asp-page="./Edit" asp-route-id="@contact.Id">
                            Edit
                        </a>
                        <button type="submit" asp-page-handler="delete" asp-route-id="@contact.Id">
                            Delete
                        </button>
                    </td>
                </tr>
            }
        </tbody>
    </table>
    <a asp-page="Create">Create New</a>
</form>
`}</code></pre>
    <h2>{`Controller`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using Microsoft.AspNetCore.Mvc;
using YourNamespace.Models;

namespace YourNamespace.Controllers
{
    public class MyController : Controller
    {
        private readonly IMyRepository _myRepository;

        public MyController(IMyRepository myRepository)
        {
            _myRepository = myRepository;
        }

        public IActionResult Index()
        {
            // Option 1: use ViewData
            ViewData["Model1"] = _myRepository.GetMyModel1();
            ViewData["Model2"] = _myRepository.GetMyModel2();

            // Option 2: use dynamic view model
            dynamic viewModel = new ExpandoObject();
            viewModel.MyModel1 = _myRepository.GetMyModel1();
            viewModel.MyModel2 = _myRepository.GetMyModel2();

            // Option 3: Razor Pages model:
            var indexModel = new RazorPagesContacts.Pages.Customers.IndexModel();
            indexModel.Customer = _myRepository.GetCustomers();

            return View((viewModel, indexModel));
        }
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      