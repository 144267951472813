import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Wrap root element`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "path=/gatsby-browser.js",
        "path": "/gatsby-browser.js"
      }}>{`import React from 'react'
import Wrapper from 'foo'
import './src/styles/index.scss';

export function wrapRootElement({element}) {
  return <Wrapper>
    {element}
  </Wrapper>
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      