import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Installation`}</h2>
    <h3>{`Java`}</h3>
    <p><strong parentName="p">{`Darwin`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`brew install --cask temurin
`}</code></pre>
    <h3>{`Gradle`}</h3>
    <p><strong parentName="p">{`Darwin`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`brew install gradle
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      