import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Syntax`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sql"
      }}>{`-- SELECT all columns from multiple tables
SELECT * FROM \`order details\`
JOIN customers ON \`order details\`.customer_id = customers.id
JOIN products ON \`order details\`.product_id = products.id;

-- WHERE clause with multiple conditions
WHERE orders.\`status (paid/unpaid)\` = 'shipped'
AND customers.country = 'United States'
AND products.price > 50

-- ORDER BY clause with multiple columns
ORDER BY total_sales DESC, products.name ASC

-- OFFSET clause
OFFSET 10;

-- LIMIT clause
LIMIT 10;

-- GROUP BY clause
GROUP BY orders.product_id

-- HAVING clause with aggregate function
HAVING total_orders > 5

-- SELECT with LEFT JOIN
SELECT orders.id, customers.name, products.name
FROM orders
LEFT JOIN customers ON orders.customer_id = customers.id
LEFT JOIN products ON orders.product_id = products.id;

-- SELECT with RIGHT JOIN
SELECT orders.id, customers.name, products.name
FROM orders
RIGHT JOIN customers ON orders.customer_id = customers.id
RIGHT JOIN products ON orders.product_id = products.id;

-- SELECT with INNER JOIN
SELECT orders.id, customers.name, products.name
FROM orders
INNER JOIN customers ON orders.customer_id = customers.id
INNER JOIN products ON orders.product_id = products.id;

-- SELECT with INNER JOIN, multiple columns and aggregate function
SELECT COUNT(orders.id) as total_orders, SUM(products.price) as total_sales, products.name
FROM orders
INNER JOIN customers ON orders.customer_id = customers.id
INNER JOIN products ON orders.product_id = products.id

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      