import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Resources</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Apply</AnchorLink>
    </AnchorLinks>
    <h2>{`Resources`}</h2>
    <h3>{`Base`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/longhorn/kustomization.yaml",
        "path": "/base/longhorn/kustomization.yaml"
      }}>{`---
apiVersion: kustomize.config.k8s.io/v1beta1
kind: Kustomization

namespace: longhorn

resources:
  - https://raw.githubusercontent.com/longhorn/longhorn/master/deploy/longhorn.yaml

`}</code></pre>
    <h2>{`Applying`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`kubectl create namespace longhorn; \\
kubectl apply \\
    -f https://raw.githubusercontent.com/longhorn/longhorn/master/deploy/longhorn.yaml \\
    -n longhorn
`}</code></pre>
    <p><em parentName="p">{`Longhorn should be installed in the namespace `}<strong parentName="em">{`longhorn`}</strong>{`.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      