import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Kickstart`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "path=fedora-31-kickstart.sh src=/fedora-31-kickstart.sh",
        "path": "fedora-31-kickstart.sh",
        "src": "/fedora-31-kickstart.sh"
      }}>{`#!/bin/bash
pkill qemu-system-x86

deploy()
{
    echo "$*" | grep -w -- "--deploy" || return 0
    printf "\\nHost: "
    read -r HOST

    ssh-keygen -f ~/.ssh/known_hosts -R "$HOST"

    ssh-keygen -f "$HOST"_ed25519 -t ed25519 -C ''
    chmod 600 "$HOST"_ed25519.pub

    SSH_OPTS="-o StrictHostKeyChecking=no -o UserKnownHostsFile=/dev/null -o LogLevel=QUIET"

    ssh-copy-id $SSH_OPTS -i "$HOST"_ed25519.pub root@"$HOST"

    scp $SSH_OPTS -i "$HOST"_ed25519 ks.sh root@"$HOST":ks.sh
    ssh $SSH_OPTS -t -i "$HOST"_ed25519 root@"$HOST" "sh ks.sh"

    echo "$HOST deployed"

    exit 0
}

selectDisk()
{
    lsblk -dn -o name,size
    printf "\\nTarget device: "
    read -r DISK

    # Validate selection!
    # Check space!
}

getDependencies()
{
    rm -rf /lib/modules/

    apt update
    apt install -y qemu &

    wget -c "https://dl.fedoraproject.org/pub/fedora/linux/releases/31/Server/x86_64/os/isolinux/initrd.img" -P releases/f31 &
    wget -c "https://dl.fedoraproject.org/pub/fedora/linux/releases/31/Server/x86_64/os/isolinux/vmlinuz" -P releases/f31 &
    wget -c "https://dl.fedoraproject.org/pub/fedora/linux/releases/31/Server/x86_64/iso/Fedora-Server-netinst-x86_64-31-1.9.iso" -P releases/f31 &

    # Check hashes!

    wait

    apt purge
}

createConfig()
{
    OEMDRV=$(mktemp -d -t ks-XXXXXXXXXX)

    # Add key
    tail -n1 ~/.ssh/authorized_keys > "$OEMDRV"/authorized_keys

    # Add repositories
    mkdir "$OEMDRV"/repos

    cat <<- 'EOF' > "$OEMDRV"/repos/docker.repo
    [docker]
    name=Docker
    baseurl=https://download.docker.com/linux/fedora/31/x86_64/stable
    enabled=1
    gpgcheck=1
    repo_gpgcheck=1
    gpgkey=https://download.docker.com/linux/fedora/gpg
    EOF

    cat <<- 'EOF' > "$OEMDRV"/repos/kubernetes.repo
    [kubernetes]
    name=Kubernetes
    baseurl=https://packages.cloud.google.com/yum/repos/kubernetes-el7-x86_64
    enabled=1
    gpgcheck=1
    repo_gpgcheck=1
    gpgkey=https://packages.cloud.google.com/yum/doc/yum-key.gpg https://packages.cloud.google.com/yum/doc/rpm-package-key.gpg
    EOF

    # Add kickstart config
    cat <<- 'EOF' > "$OEMDRV"/ks.cfg
    install
    eula --agreed

    # System timezone
    timezone Europe/Amsterdam

    # System language
    lang en_US.UTF-8

    # Keyboard layouts
    keyboard --vckeymap=us --xlayouts=''

    # SELinux configuration
    selinux --enforcing

    # Use text mode install
    text

    # Do not configure the X Window System
    skipx

    # System authorization information
    auth --useshadow --passalgo=sha512
    rootpw --iscrypted $6$zBVuibdR$QIvqzBTd8J1sN3Iwaj9SmaffDHiGlY8JCdQJ4xtRneiDb7CE3X2BDjHCr8I7e9ECsCjQi2lU0kgEbdgchs5Wb0

    # Use network installation
    url --mirrorlist=https://mirrors.fedoraproject.org/mirrorlist?repo=fedora-$releasever&arch=$basearch
    #url --metalink=https://mirrors.fedoraproject.org/metalink?repo=fedora-$releasever&arch=$basearch
    #url --url=https://download.fedoraproject.org/pub/fedora/linux/releases/$releasever/Server/$basearch/os/

    repo --mirrorlist=https://mirrors.fedoraproject.org/mirrorlist?repo=updates-released-f$releasever&arch=$basearch --name=fedora-updates --cost=0
    #repo --name=fedora
    #repo --name=updates
    #repo --name=updates-testing

    # Disk partitioning information
    ignoredisk --only-use=sda
    clearpart --all --initlabel --drives=sda
    zerombr

    part /boot --fstype ext4 --size=512
    part / --fstype ext4 --size=1 --grow

    # System bootloader configuration
    bootloader --location=mbr --boot-drive=sda --append=" crashkernel=auto"

    # Network information
    network --device=eth0 --bootproto=dhcp --hostname=linux
    #network --device=link --bootproto=dhcp --ipv6=auto --onboot=yes --activate

    # Firewall configuration
    firewall --enabled --service=http,ssh

    # Disable firstboot
    firstboot --disable

    # Shutdown after installation
    shutdown

    %packages
    @core --nodefaults
    fedora-release
    dnf-automatic
    cronie
    chrony
    python
    %end

    # System services
    services --enabled="crond,chronyd,dnf-automatic-install.timer"

    %post --nochroot --log=/mnt/sysimage/root/anaconda-post-nochroot.log
    #!/bin/sh
    set -x -v

    mkdir OEMDRV /mnt/sysimage/root/.ssh/ /mnt/sysimage/etc/yum.repos.d/
    mount /dev/sr1 OEMDRV

    # Copy configuration
    cp OEMDRV/authorized_keys /mnt/sysimage/root/.ssh/
    cp OEMDRV/repos/* /mnt/sysimage/etc/yum.repos.d/
    cp OEMDRV/firstboot.sh /mnt/sysimage/root/
    chmod 600 /mnt/sysimage/root/.ssh/authorized_keys

    umount OEMDRV
    %end

    %post --log=/root/anaconda-post.log
    #!/bin/sh
    set -x -v

    # Lock root
    chage -E 0 root
    passwd -l root

    # Create first boot task
    echo "@reboot root /bin/sh /root/firstboot.sh > /root/firstboot.log 2>&1" >> /etc/crontab
    crontab /etc/crontab

    %end
    EOF

    # Add first boot script
    cat <<- 'EOF' > "$OEMDRV"/firstboot.sh
    #!/bin/sh
    set -x -v

    # Destroy first boot task
    sed --in-place '/firstboot/d' /etc/crontab

    # Wait for network
    TIME=0
    until $(ip route show | grep default &>/dev/null); do :; done
    DURATION=$TIME; echo "$((DURATION / 60)) minutes $((DURATION % 60)) seconds elapsed"

    # Detect network device
    ETH=$({ ip route show | awk '/default/ {print $5}'; nmcli -t con; } | awk -F: -v dev=$(head -n 1) '$4 == dev {print $1}')

    # Disable DHCP DNS
    nmcli con mod "$ETH" ipv4.ignore-auto-dns yes
    nmcli con mod "$ETH" ipv6.ignore-auto-dns yes

    # Configure DNS
    nmcli con mod "$ETH" ipv4.dns "213.186.33.99 213.186.33.199"
    nmcli con mod "$ETH" ipv6.dns "2001:41d0:3:163::1 2001:41d0:c7:3:1::1"

    # Applying network configuration
    nmcli con reload "$ETH"

    # Docker packages
    script -eqc "dnf -y install docker-ce"
    pip install --upgrade pip docker-compose
    systemctl enable --now docker

    # Kubernetes packages
    script -eqc "dnf -y install kubeadm kubelet kubectl"
    systemctl enable --now kubelet

    # Unlock root
    chage -E -1 root
    EOF

    # Convert to ISO
    rm -f ks.iso
    genisoimage -U -V OEMDRV -o ks.iso "$OEMDRV"
    rm -rf "$OEMDRV"
}

install()
{
    qemu-system-x86_64 \\
    "$([ -e /dev/kvm ] && echo "-enable-kvm" || echo "-no-kvm")" \\
    -m 1280M -localtime \\
    -device virtio-net-pci,netdev=eth0 -netdev type=user,id=eth0 \\
    -kernel releases/f31/vmlinuz \\
    -append " console=tty0 console=ttyS0,115200n8" \\
    -initrd releases/f31/initrd.img \\
    -drive format=raw,media=cdrom,index=0,file=releases/f31/Fedora-Server-netinst-x86_64-31-1.9.iso \\
    -drive format=raw,media=cdrom,index=1,file=ks.iso \\
    -drive format=raw,media=disk,file=/dev/"$DISK" \\
    -serial mon:stdio -nographic
}

deploy "$@"
selectDisk
getDependencies
createConfig
install
`}</code></pre>
    <p><em parentName="p">{`This is a Bash script that prepares a Fedora 31 virtual machine for deployment on a remote server.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      