import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Setup`}</h2>
    <p><strong parentName="p">{`Install dependencies`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`composer require --dev perftools/php-profiler
`}</code></pre>
    <p><strong parentName="p">{`Bootstrap code`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-php",
        "metastring": "path=bootstrap.php",
        "path": "bootstrap.php"
      }}>{`$profiler = new \\Xhgui\\Profiler\\Profiler([
    'save.handler' => \\Xhgui\\Profiler\\Profiler::SAVER_FILE,
    'save.handler.file' => [
        'filename' => '/mnt/files/xhprof/xhgui.data.jsonl',
    ],
]);

$profiler->start();
`}</code></pre>
    <p><strong parentName="p">{`(*) Update configuration`}</strong></p>
    <p>{`If your application makes use of services that run on localhost you should adjust
your configuration to use an alternative DNS name:`}</p>
    <pre><code parentName="pre" {...{}}>{`- localhost:3306
+ host.docker.internal:3306
`}</code></pre>
    <p><strong parentName="p">{`Create Docker setup`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=docker-compose-xhgui.yml",
        "path": "docker-compose-xhgui.yml"
      }}>{`version: "3.8"

services:
  nginx-xhprof:
    image: wodby/nginx:1.19
    environment:
      NGINX_VHOST_PRESET: php
      NGINX_BACKEND_HOST: xhprof
      NGINX_SERVER_ROOT: /var/www/html/public
    ports:
      - 8000:80
    volumes:
      - app:/var/www/html

  xhprof:
    image: wodby/php:8.1-dev
    environment:
      PHP_DEBUG: true
      PHP_XHPROF: true
      PHP_XHPROF_SAMPLING_INTERVAL: 100000
      PHP_XHPROF_SAMPLING_DEPTH: INT_MAX
      PHP_XHPROF_COLLECT_ADDITIONAL_INFO: 0
      PHP_XHPROF_OUTPUT_DIR: /mnt/files/xhprof
    volumes:
      - app:/var/www/html
      - xhprof:/mnt/files/xhprof
    depends_on:
      - nginx-xhprof

  xhgui:
    image: ghcr.io/nedix/xhgui-docker
    volumes:
      - xhprof:/data/xhprof
    ports:
      - 8001:80
    depends_on:
      - xhprof

volumes:
  app:
    driver: local
    driver_opts:
      device: \${PWD}
      o: bind
      type: none
  xhprof:
    driver: local
`}</code></pre>
    <h2>{`Usage`}</h2>
    <h3>{`Step 1.`}</h3>
    <p>{`Start the containers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`docker compose -f docker-compose.yml -f docker-compose-xhgui.yml up -d
`}</code></pre>
    <h3>{`Step 2.`}</h3>
    <p>{`Navigate to your page on `}<a parentName="p" {...{
        "href": "http://localhost:8000"
      }}>{`localhost:8000`}</a>{` to capture the profiling data.`}</p>
    <h3>{`Step 3.`}</h3>
    <p>{`Execute the following command to load the data into XHGui:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`docker compose -f docker-compose-xhgui.yml exec xhgui php /var/www/xhgui/external/import.php -f /data/xhprof/xhgui.data.jsonl
`}</code></pre>
    <h3>{`Step 4.`}</h3>
    <p>{`Find XHGui on `}<a parentName="p" {...{
        "href": "http://localhost:8001"
      }}>{`localhost:8001`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      