import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://learn.microsoft.com/en-us/dotnet/standard/design-guidelines/"
        }}>{`Framework design guidelines (Microsoft)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://learn.microsoft.com/en-us/dotnet/architecture/modern-web-apps-azure/"
        }}>{`Architect Modern Web Applications with ASP.NET Core and Azure (Microsoft)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      