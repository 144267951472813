import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-haskell"
      }}>{`-- Module declaration
module Main where

-- Importing modules
import Data.Time (UTCTime, getCurrentTime)
import Text.Printf (printf)
import Control.Exception (catch, SomeException)

-- Type declarations
data Candy = Candy {
    name :: String,
    price :: Double,
    calories :: Double
} deriving (Show)

-- Functions
add :: Double -> Double -> Double
add x y = x + y

subtract :: Double -> Double -> Double
subtract x y = x - y

-- IO
main :: IO ()
main = do
    putStrLn "Welcome to the candy shop!"

    -- Using IO to show current time
    time <- getCurrentTime
    printf "Today is %s\\n" (show time)

    -- Using let binding to define local variables
    let candy1 = Candy "Chocolate" 2.5 250
    let candy2 = Candy "Gummy Bears" 1.5 150

    -- Lists
    let candies = [candy1, candy2]

    putStrLn "Our selection of candies:"

    -- List comprehension and pattern matching
    forM_ candies $ \\candy -> do
        let Candy name price calories = candy
        printf "- %s: $%.2f (%.0f calories)\\n" name price calories

    -- Exception handling
    let invalidCandy = Candy "" 0 0
    putStrLn "Trying to add invalid candy to the list:"
    catch (addCandy invalidCandy candies)
          (\\e -> putStrLn ("Error: " ++ show (e :: SomeException)))

-- Throwing exceptions
addCandy :: Candy -> [Candy] -> IO [Candy]
addCandy c cs
    | name c == "" = error "Invalid candy name"
    | price c <= 0 = error "Invalid candy price"
    | calories c <= 0 = error "Invalid candy calories"
    | otherwise = return (c:cs)

-- Function composition to calculate total calories
totalCalories :: [Candy] -> Double
totalCalories = sum . map calories

-- Function composition and list processing to calculate total price of candies
-- above a certain calorie threshold
totalPriceAboveCalories :: [Candy] -> Double -> Double
totalPriceAboveCalories candies threshold =
    sum . map price . filter (\\c -> calories c > threshold) $ candies
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      