import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Functions in Haskell have no side effects and cannot modify the state of the program.`}</p>
    <p>{`One key benefit of Haskell’s purely functional approach is that it makes the language well-suited to parallel and concurrent programming, as there are no concerns about shared mutable state.`}</p>
    <p>{`Haskell also offers lazy evaluation, which allows computations to be performed on demand rather than all at once. This leads to more efficient use of resources and improved performance.`}</p>
    <p>{`Haskell’s libraries and resources make it a viable choice for a wide range of applications, from web development to scientific computing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      