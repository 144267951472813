import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`ECMAScript`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Candy class
class Candy {
  /**
   * A class representing a type of candy sold in the candy shop.
   * @constructor
   * @param {string} name - The name of the candy.
   * @param {number} price - The price of the candy in dollars.
   */
  constructor(name, price) {
    this.name = name;
    this.price = price;
  }
}

// Customer class
class Customer {
  /**
   * A class representing a customer of the candy shop.
   * @constructor
   * @param {string} name - The name of the customer.
   * @param {number} money - The amount of money the customer has in dollars.
   */
  constructor(name, money) {
    this.name = name;
    this.money = money;
    this.cart = [];
  }

  /**
   * Adds a candy to the customer's cart.
   * @param {Candy} candy - The candy to add to the cart.
   * @param {number} quantity - The quantity of the candy to add to the cart.
   */
  addToCart(candy, quantity) {
    this.cart.push({ candy: candy, quantity: quantity });
  }

  /**
   * Buys the items in the customer's cart and updates the customer's money and the candy shop's inventory.
   * @param {CandyShop} candyShop - The candy shop to buy the items from.
   */
  buyItems(candyShop) {
    let totalCost = this.calculateTotalCost();

    if (totalCost > this.money) {
      throw new Error("Not enough money to buy items");
    }

    for (let item of this.cart) {
      let candy = item.candy;
      let quantity = item.quantity;

      if (candy.quantity < quantity) {
        throw new Error(\`Not enough \${candy.name} in stock\`);
      }

      candyShop.sellCandy(this, candy);
    }

    this.money -= totalCost;
    this.cart = [];
  }
}

// CandyShop class
class CandyShop {
  constructor(name, candies) {
    this.name = name;
    this.candies = candies;
  }

  /**
   * Sells a candy to a customer.
   * @param {Customer} customer
   * @param {Candy} candy
   */
  sellCandy(customer, candy) {
    if (! candy) {
      throw new Error(\`No such candy (\${candy.name}) in stock.\`);
    }

    if (customer.money < candy.price) {
      throw new Error(\`Customer \${customer.name} does not have enough money to buy \${candy.name}.\`);
    }

    this.candies.splice(this.candies.indexOf(candy), 1);
  }
}

// Exporting as a module
module.exports = {
  Candy,
  Customer,
  CandyShop
};

// Exporting as named exports
export { Candy, Customer, CandyShop };

// Exporting with a custom name
export { CandyShop as SweetShop };

// Importing with CommonJS
const CandyShop = require("./candy-shop");

// Importing a default export with ES6
import myModule from './myModule.js';

// Importing specific properties or methods
import { Candy, Customer, CandyShop } from "./candy-shop;

// Importing with a custom name
import { myModule as customName } from './myModule.js';

// Importing all exports as an object
import * as allExports from './myModule.js';

// Importing with a dynamic import() function
const dynamicImport = async () => {
    const myModule = await import('./myModule.js');
    // Use myModule here
}

`}</code></pre>
    <p><strong parentName="p">{`TypeScript`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Classes and Interfaces
interface Person {
  name: string;
  birthDate: Date;
}

class Patient implements Person {
  name: string = 'patientName';
  birthDate: Date;

  constructor(name: string, birthDate: Date) {
    this.name = name;
    this.birthDate = birthDate;
  }

  getName(): string {
    return this.name;
  }

  getBirthDate(): Date {
    return this.birthDate;
  }
}

// Creating an object
const name: string = "John Smith";
const birthDate: Date = new Date('01-01-1970');
const isNewPatient: boolean = true;

const patient: Patient = new Patient(name, birthDate);

// Variables and Data Types
let age: number = 30;
const isMale: boolean = true;
const favoriteFruits: string[] = ['apple', 'banana', 'orange'];

// Arrays and Objects
const patientData: { name: string, birthDate: Date, isNewPatient: boolean } = {
  name,
  birthDate,
  isNewPatient,
};

// Conditional statements
if (isNewPatient) {
  console.log("Is new patient");
} else {
  console.log("Is returning patient");
}

// Loops
for (let fruit of favoriteFruits) {
  console.log(fruit);
}

// Functions
function addNumbers(x: number, y: number): number {
  return x + y;
}

// Exception Handling
try {
  // Creating an object and Exception Handling
  const patient = new Patient(name, birthDate);
  patient.name = '';
} catch (e) {
  console.log('Error: ' + e.message);
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      