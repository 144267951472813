import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Row mdxType="Row">
      <Column colMd={3} colLg={3} mdxType="Column">
  <ImageCard subTitle="Laravel" subTitleColor="dark" actionIcon="arrowRight" iconColor="dark" href="/technologies/frameworks/laravel/overview/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA3XAAAN1wFCKJt4AAACtUlEQVQ4y3VUSWsWQRT8vi+KRkERN0RCvYh6eiWIKGLcRUFUEC8KbojGuMUocd9Q4ha3g149CeJV8Sx48OqP8Oif6JKe7p6ZaGwYZqanp169V/VeR0RHbh0RfYHxbvk93tEVrRv30zd06jP1XrnQqVYLMH7oF+2siDei7ZLXAN2pAOg1gdFr/kcnbXo8jK0iJuQ4IGJ5IEZFXBdtIDGrAnZDxboK1B/c+jKRnvJ+QV4t4peIw3W0dP8gx2e1WEbWYhX0lWjPA7Gl3nd0C8MhEZdFOyLiiYgjVdpuu+W4LWJeDrJCxGMRJ0TMFW2xiEsi7gXHwohVGA4Fx+kU3bYFt4+lJnLcFTE/v0+K+B5oa2rhiO0ifoo406ohIsNz+Xm53EZTCjZXjjuhMKSNi7YuZYNHIp6KdkzEDhHHK8Bc7CE5RnL6K+R2Oz/PjoAiFoQkxJhoyzLzG3IczcxjkFN/AdpISCCDcruZAedUDBPznohPchwMSYBDwbExA46G6QDVAN7KVuqExDCm9VLEvsz4vDxfxC0RY5WHoyjTMIwpX40sgmOLHF9FjIjob/yI/SJ+i3gvVueT6SuVE/0oSgE0ub0WbVjEg0AsDUVxYoaIk6Jdqxg7NmQ7zWw6JbXUJrkNV5HcZon4JtqT0qPZ2DujmYPjZHbDQTn2ZEK9up9zgbeJuJDViz/Pzr6aEG2zaA9EjItY1PLnKTn2NoBlOKSNJSLeiVHB9gQxF+1LtEUoTFPAWMO3wTFQt1xZgaXZMSsXf1K09aWnQ6xXM43WivZMxEXR5tQ9HsUoK6fQbVKppst9MdrDVop2RcQq0a6LeChisJVBGmttwNBYoRqyZe4FRgXthWg/RLyO/d4Sqa8lVlWGKas1lkrUXmuErU0DNc+9mI1bZ8pQ/d8KTdrxudfUEf9O5v+sP8U0JqqsT+ljAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Laravel",
              "title": "Laravel",
              "src": "/static/e6f015b5d73bcb70a812f1216672ff47/36a81/laravel.png",
              "srcSet": ["/static/e6f015b5d73bcb70a812f1216672ff47/7fc1e/laravel.png 288w", "/static/e6f015b5d73bcb70a812f1216672ff47/36a81/laravel.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={3} colLg={3} mdxType="Column">
  <ImageCard subTitle="Symfony" subTitleColor="dark" actionIcon="arrowRight" iconColor="dark" href="/technologies/frameworks/symfony/overview/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACG0lEQVQ4y52UPWsVQRiFZ29EE4NKQAQRQYyFiDhn7949u1737DUJiigIWtoa0NImdiKIWvoHhNiJjSg2SiA/wNpCrC20t5bI7L6b7P3Q5DrwsjOzM8+cmffDuSkaUm2bD9aXu5DJgXJTtbBhxDqgZkBFCMBpoCOgaG7/wvBcpijO5IKNNc9iXE26Awvfbj4I/WVQt0EtNv/GgKNK4qycya9db65XwXyqWVBvQN0E9RTUFqhjzZ6/AavNcVa6pFh2NehSNQb1AdQnW9cHtQ5qvtk7CRjZ9zioF6Ceg1pFVl2zMEU3ziW9MD5ph7ghoM933sn3CpfWqr6C2ujWG1ZA3Qe1GYA+1T5QS6B++FQdn44AY5YVqKXulCn57NNi1ua8zf0EdQvUI1D3gsI4K6OJwKqflY0XN+OsDIC3dq2rBtywA48GQHJx4BqF2y0AY5YdO+UlqCdIi8OgvhnkDKg164c3dYtn48r7WrnixhwCVrI7qJV8DF40VevVm/WKOQuVLbuusyxpxWrRUlh7sLEjoO6CegDqHagy7S85U/sb1IkmGkISTGzd/PJoyASbb4XEaVP33pzQ+WfeTkj68D3UyoBVA/aaNX63QpDU124ULoD6Beq1efw7qMeNum5e7q2ytFQeBPUF1CtQz0A9rGB5GYUEOB/33UB39gyMzGsHQCWWgkPB+z91L2qVrDpEhsdTlPdxBw1V5LBmt/YHVjvlpt6p6lIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Symfony",
              "title": "Symfony",
              "src": "/static/e621f77f2ae4046165b805a4f62dda88/3787d/symfony.png",
              "srcSet": ["/static/e621f77f2ae4046165b805a4f62dda88/7fc1e/symfony.png 288w", "/static/e621f77f2ae4046165b805a4f62dda88/3787d/symfony.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={3} colLg={3} mdxType="Column">
  <ImageCard subTitle="Gatsby" subTitleColor="dark" actionIcon="arrowRight" iconColor="dark" href="/technologies/frameworks/gatsby/overview/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAEU0lEQVQ4y22Ua0xbZRjHS+sSP/lhMTHzo+O6i7Tn9IpsCl628MFbNNkol7H2nF6A6pgLydSgDG/LXKQUem5cxjoGGzNZiGS4ZDgvRGFxMahBh05nEGXABjpte3rexzzvaQtGm7w9J+/znt/7/J+bwZD+caxo4BmBLo4VjemV3k/bWIG+c6xg4hkxZ80uGP71oxAmbWRFE8+KBq9FMDQ9duL+htLu3UGXwtW7FE/jju7yQ7v67+UoXD/LrTmxBuPTB/x2yei3S4agSynw26VY7ZbIbXdeGNy57frKC8O+rZ0LAYccCbqUTX6bZPDZpCwUFWRdRljQKRsCDrnaa47Gqws6oOX5MzAiXVGnLs6qk2PX1PcjX6iHnxwAtHEWYTHgkJ8IOGSDzyqaMiqzMtGzgEOu2f9gFwScMkx9OJsAIKkbMze1z0e/1ybHZrX567c0AEh9NPxN3GsRgJ51yOU+m4Qwkw5kRCPKDTrlQq85mgg4ZJibXUrenFtVj1QOp2qKIgTlVua2w75tneS4f0S7sxonP07/Ts96LcJcQ2n3RpowVsjBPyNnoZJjVflhmLxwLbE0v5rkGEFFaT6bBH6bRHD5rCLsfeA9aK6IkUuD01poZ0/Ca44CbxXbaFIZ0UR1YzYxAa17h0FNptRXnhlUa7dEUA7hWZFwjAC48N1vlwjKrS7owH2Nt4pom+FZ8W6aaQTWlyi7awo7IBwaVQFAa28c1dy57fgxrAMinMatbnsX8RRH9ac5Svc5RthK61F3VeBQmjsvnBp6d0LToR8gFDJQ+pFFIAcf7ycvP30aMNuHnxogLzzcq6HHvFUsp0mhJWOT9tdt74K+18fVoEshsTc/zkIxGRh8vLC2KEImRmY0QghZWbpDAIAMHfuMxrqxtPsRKhmBoR09ZVi056OTyYVfbmPxkoG3P9Ey8jNQr0UgzRUxreW5Ia3NfS6ViKtaZ9MFUpUfVg+U9+Vn6/DQrpMb67Z1/tZcEQMAUG98t4ixIgPvfPofKEp/dtMx6G0Z1/5ciav1LgU8xdGrva+NG3XJ2OisiEUdxsSM9ny5gtSfvl2g0FNv6Z6GQ6Na5eZ2wNW65yzuYWgSWGpBl3KAt2bKhhVzsBeDTvk+jhEWMMBXLv6wjB/MX78FnuIucvKNyxTadXBMQ8/QdmlwOoGl5bOKX9WXKBu85mi6lxm99bB9Ag65DAsVS+LM8YnlZCL19+Kvq6nQzh5y+qguf2Xpr1T/kctJhPGsuOy3S0U+K2UY9aSw+ujBBsdGDzjkUp4Vf8bM1ZcoEH1pLH7Ue/6P6oKOVOues3QPZfqs4lW/XSrEicNbRVNmNmaB6ClC8bagS7kn6JRf9ZijX1flh1NYUumyiXuKo1NBlxJqeKj7LurZOlh20GYGZGbyYG9jkNvc5zY0PXqiAL3mGKHkxbK+zQCAFxs8NGbrp/r/Te01Yw5mPzvFGX3089lLBVP6qStkxCzoH6KSB3T8T0YwAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Gatsby",
              "title": "Gatsby",
              "src": "/static/99353e5ac9a4344f013bea93f860d25a/36a81/gatsby.png",
              "srcSet": ["/static/99353e5ac9a4344f013bea93f860d25a/7fc1e/gatsby.png 288w", "/static/99353e5ac9a4344f013bea93f860d25a/36a81/gatsby.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={3} colLg={3} mdxType="Column">
  <ImageCard subTitle="Angular" subTitleColor="dark" actionIcon="arrowRight" iconColor="dark" href="/technologies/frameworks/angular/cordova/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADo0lEQVQ4y3WUT2gcVRzHvzOzMaI9WFGwItg2m2TLZufNy0ZzkOhBqGJBtDatHjwUivgHexAj4k2lF71ZD9ZDTxoEexBBigruzrxdW0VFIdVmZ15qNLQNie1CUpvu7LyfvJk3202pD368YWE/fH/v9/19gRuOBEcEL71DeFgAt0J4ThPM8eFaTTAIMARw0/umJ4dkoAwm4TkS3F4w4CZc+GC6LAHmCAOuwd0MzwFRBrGjDGR+93AO7K7Qrh46ZfOXa6hs882fjUq7H55Cc2W5OtPuFgn+VATvU2mNr4aoUANlCizWFnBPCLADAuyOoA/eA8rrwIKE94iEd1TCW4zgkbTGKUKZFotPdn8Zeyauo0TC8kjAJQF2XoB9LMB2C7DBfoWOAe5PIWlxXYl0qnELO5K1YyfU8uffqBq2K+HwWIB1AzAKMrCuFw2woNuzszfjj2lQBN6R4F1pcRWhQue2Pqy6K5dVcq2jfrhvt/KxS6tUAm4SwN0wwOcM0NEtW2YAZQlvw6hT0plQLQzRyuH3KD+Lb39ENewg4XAlwFSQwXRNGaCtW7VMy3dG8Np5u9kbcuqeX6H2J1/RxQ9mKV67Qs3bJinAGPUB/xVgRQO0evaQ2VCiFOhUkxaKdHHfTKrsz7F99NM9j6bf4QvvGJXjiQGuCrAtm6acTTp9Rz8FWrwboayuNn+leGmZFktP088799D6XERXzkgKUNEDSUy7c7rVTT6U4JYZzKy0qxRiNF566KCi/zlzT7yiahjqCodr4NdBukVurpD3rCPB39fAFoY6a7MnVXL1mlp+9k26MP0Gnd0/Q79Pv06df9rq0smGqqEYZ55kx4Ns/eweUIIX0rsw8VqIXfQ3O7BBRKr94WdqHttUC6MUYJi+xd1q8d1jqfLfpg5u1DFCjYHqEQMs9LecKlwYnNwbokQX9rxK61/6nb9Ke5MIYxQNPKC+H6gqH2X68f7Hk9Uvvuv8MT1DBnjIrGBB37lCRCijhe22tKvPR6jIEKOkjS0tHofwVDOzSawtU8cw+SgvCZu/VMfILQKVXqT1xRdDC0VIu4qFWydvl4WJt6TFL+lVDOFRBnRJWGy9URg/0hyc2CocDh8lCDOQxvUIM6HqVNMBhRhGhIq20r0S/GgIL25mu3u8AbbTRxl1jGhFjrBYD7jpRL1g5WYduaPDdR4ezsJ78DTY1Ok0YN0MlAUtgtx/N0/u3OA9uDUPz9FQDTsFZmt7BH0p3bgB9h8m42nV6aDraQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Angular",
              "title": "Angular",
              "src": "/static/2fb0a23ae38ff99569c176bd96191629/3cbba/angular.png",
              "srcSet": ["/static/2fb0a23ae38ff99569c176bd96191629/7fc1e/angular.png 288w", "/static/2fb0a23ae38ff99569c176bd96191629/a5df1/angular.png 576w", "/static/2fb0a23ae38ff99569c176bd96191629/3cbba/angular.png 1152w", "/static/2fb0a23ae38ff99569c176bd96191629/0b124/angular.png 1728w", "/static/2fb0a23ae38ff99569c176bd96191629/6a339/angular.png 2048w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={3} colLg={3} mdxType="Column">
  <ImageCard subTitle="ASP.NET" subTitleColor="dark" actionIcon="arrowRight" iconColor="dark" href="/technologies/frameworks/asp.net/overview/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAADSklEQVQ4y7VUS28bZRQ900aAhAQBhIqQICFpSuwkM/GMPX6mbtM83CiJQ+KMk/qRxJ4ZP8GJKKqoUKv8A1Al2PAfWLFiAwsWCDWN4xnbmbYeO/ErdlwJ6IpFBpWqkVoRsCp6pCvdT/fe892zuAeapuGk+Dc8qh8dHT3V/+h9nDyJdgmf7T2eOemndjY8kfB43f8D7UpsG7Xin9gMlBFzFrHB/QiBbSDCNrE+XkTc1oBgLUFgVURH8ohZ8ohdyiI5tgV+JAfBkYNwPgPekYVolyDYZOCrTxoQTSXwjArRWII4vgfR0MS1b35F3HqIsLEF7ScNC2SW8JAZgusvYFGnENPdCuF6tQx3V4GY6skRl15vYbTzEPD3q+CZIni6gIhxD7GJIkS6+cpn0JCwtjrC7CG0BxoWh7PwkDvw9pfADSiY7roLFg8x33sfM305TL1Tw+V3q0DEWiREiwrRrOqj5kKn6Kh+ELc39OujpTNxW93Km2uDH+lyCLI5wUdLE2sWhVozS04/s7u5SiszQWPuuo/Jfb1C7+qD9C4QoDL41qlBMKscz5aYsKNOhewHZ1MX1TMxS40SzGXHVPcdBE3yLZ9B+i7AyBt+Oh1doqQfVhmFXh5OL3ipnS9n9T/DrfvlFMJGFWGm2Bs9X5wUbeWJiKnxGm8+MEQde66kvT7K09U3U5PltwNG+YbfkI366IzkN2wFl8md70Xbbo+XvOPkyPQNjz6NBV26AzxTBm8sv3fV20R85I+3UhcPdFG2MRAfqbyfsjde9rKllwT7/kyIVVyrjPpGgMnF10wZyjcs3wzQ+TE/KXVfoeQx74AETi+dAs9UEBquYH26SkQtD5E01hHuqiBmr+Ljnt+AzlsI2/axTObhHSzAS8pYHkpjri8LT78C7pyM+Q9lzJ2VCXevDHDd+whRFawM3EPE9gAJuo5QbxUxe41ImFqYGlIQsu4TS2SeWBq6C47Mnl4aTBNzfdnTnnMKsfiY8G+y2R7p8aVcYbdxc6UIK37HhruJxHgL8x0arn96G0lHE4kLFXwxex+bsyVcu3wPn7tlXHWlsT6ZwYZrC6nxNJIXtpFwbr+A03shhE/sqh3b+i9f/EdzfV6FT/nh80p/dvYveXUhVKBy9iIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "ASP.NET",
              "title": "ASP.NET",
              "src": "/static/8a4f6ccbae0fc500ca78003b2838c96d/3cbba/asp-net.png",
              "srcSet": ["/static/8a4f6ccbae0fc500ca78003b2838c96d/7fc1e/asp-net.png 288w", "/static/8a4f6ccbae0fc500ca78003b2838c96d/a5df1/asp-net.png 576w", "/static/8a4f6ccbae0fc500ca78003b2838c96d/3cbba/asp-net.png 1152w", "/static/8a4f6ccbae0fc500ca78003b2838c96d/4c487/asp-net.png 1280w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      