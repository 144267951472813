import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A key feature of PHP is its ability to interact with various databases and data formats, making it a powerful tool for managing data-driven websites and applications. Additionally, PHP offers a large set of built-in functions and libraries, as well as an extensive community.`}</p>
    <p>{`PHP was originally designed for server-side scripting, but has evolved to support a wide range of applications, including command-line scripting and desktop applications.`}</p>
    <p>{`PHP’s performance has improved significantly over the years, making it a viable option for large-scale applications that require high levels of performance and scalability.`}</p>
    <h2>{`Frameworks`}</h2>
    <Row mdxType="Row">
      <Column colMd={3} colLg={3} mdxType="Column">
  <ImageCard title="Laravel" titleColor="dark" actionIcon="arrowRight" iconColor="dark" href="/technologies/frameworks/laravel/overview/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA3XAAAN1wFCKJt4AAACtUlEQVQ4y3VUSWsWQRT8vi+KRkERN0RCvYh6eiWIKGLcRUFUEC8KbojGuMUocd9Q4ha3g149CeJV8Sx48OqP8Oif6JKe7p6ZaGwYZqanp169V/VeR0RHbh0RfYHxbvk93tEVrRv30zd06jP1XrnQqVYLMH7oF+2siDei7ZLXAN2pAOg1gdFr/kcnbXo8jK0iJuQ4IGJ5IEZFXBdtIDGrAnZDxboK1B/c+jKRnvJ+QV4t4peIw3W0dP8gx2e1WEbWYhX0lWjPA7Gl3nd0C8MhEZdFOyLiiYgjVdpuu+W4LWJeDrJCxGMRJ0TMFW2xiEsi7gXHwohVGA4Fx+kU3bYFt4+lJnLcFTE/v0+K+B5oa2rhiO0ifoo406ohIsNz+Xm53EZTCjZXjjuhMKSNi7YuZYNHIp6KdkzEDhHHK8Bc7CE5RnL6K+R2Oz/PjoAiFoQkxJhoyzLzG3IczcxjkFN/AdpISCCDcruZAedUDBPznohPchwMSYBDwbExA46G6QDVAN7KVuqExDCm9VLEvsz4vDxfxC0RY5WHoyjTMIwpX40sgmOLHF9FjIjob/yI/SJ+i3gvVueT6SuVE/0oSgE0ub0WbVjEg0AsDUVxYoaIk6Jdqxg7NmQ7zWw6JbXUJrkNV5HcZon4JtqT0qPZ2DujmYPjZHbDQTn2ZEK9up9zgbeJuJDViz/Pzr6aEG2zaA9EjItY1PLnKTn2NoBlOKSNJSLeiVHB9gQxF+1LtEUoTFPAWMO3wTFQt1xZgaXZMSsXf1K09aWnQ6xXM43WivZMxEXR5tQ9HsUoK6fQbVKppst9MdrDVop2RcQq0a6LeChisJVBGmttwNBYoRqyZe4FRgXthWg/RLyO/d4Sqa8lVlWGKas1lkrUXmuErU0DNc+9mI1bZ8pQ/d8KTdrxudfUEf9O5v+sP8U0JqqsT+ljAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Laravel",
              "title": "Laravel",
              "src": "/static/e6f015b5d73bcb70a812f1216672ff47/36a81/laravel.png",
              "srcSet": ["/static/e6f015b5d73bcb70a812f1216672ff47/7fc1e/laravel.png 288w", "/static/e6f015b5d73bcb70a812f1216672ff47/36a81/laravel.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={3} colLg={3} mdxType="Column">
  <ImageCard title="Symfony" titleColor="dark" actionIcon="arrowRight" iconColor="dark" href="/technologies/frameworks/symfony/overview/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACG0lEQVQ4y52UPWsVQRiFZ29EE4NKQAQRQYyFiDhn7949u1737DUJiigIWtoa0NImdiKIWvoHhNiJjSg2SiA/wNpCrC20t5bI7L6b7P3Q5DrwsjOzM8+cmffDuSkaUm2bD9aXu5DJgXJTtbBhxDqgZkBFCMBpoCOgaG7/wvBcpijO5IKNNc9iXE26Awvfbj4I/WVQt0EtNv/GgKNK4qycya9db65XwXyqWVBvQN0E9RTUFqhjzZ6/AavNcVa6pFh2NehSNQb1AdQnW9cHtQ5qvtk7CRjZ9zioF6Ceg1pFVl2zMEU3ziW9MD5ph7ghoM933sn3CpfWqr6C2ujWG1ZA3Qe1GYA+1T5QS6B++FQdn44AY5YVqKXulCn57NNi1ua8zf0EdQvUI1D3gsI4K6OJwKqflY0XN+OsDIC3dq2rBtywA48GQHJx4BqF2y0AY5YdO+UlqCdIi8OgvhnkDKg164c3dYtn48r7WrnixhwCVrI7qJV8DF40VevVm/WKOQuVLbuusyxpxWrRUlh7sLEjoO6CegDqHagy7S85U/sb1IkmGkISTGzd/PJoyASbb4XEaVP33pzQ+WfeTkj68D3UyoBVA/aaNX63QpDU124ULoD6Beq1efw7qMeNum5e7q2ytFQeBPUF1CtQz0A9rGB5GYUEOB/33UB39gyMzGsHQCWWgkPB+z91L2qVrDpEhsdTlPdxBw1V5LBmt/YHVjvlpt6p6lIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Symfony",
              "title": "Symfony",
              "src": "/static/e621f77f2ae4046165b805a4f62dda88/3787d/symfony.png",
              "srcSet": ["/static/e621f77f2ae4046165b805a4f62dda88/7fc1e/symfony.png 288w", "/static/e621f77f2ae4046165b805a4f62dda88/3787d/symfony.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      