import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Parent import replacer`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`class ParentImportReplacer():
    def _parent_import_replacements_callback(self, function, aliases):
        def callback(*args, **kwargs):
            original_globals = function.__globals__
            function.__globals__.update(aliases)
            value = function(*args, **kwargs)
            function.__globals__.update(original_globals)
            return value
        return callback

    def __getattribute__(self, attr):
        value = object.__getattribute__(self, attr)

        if not isinstance(value, (types.FunctionType, types.MethodType)):
            return value

        try:
            parent_import_replacements = object.__getattribute__(self, 'parent_import_replacements')
        except AttributeError as e:
            return value

        for classname, replacements in parent_import_replacements.items():
            if re.search(r'(?:^|[^<>.]+\\.)*(' + re.escape(classname) + r')(?=\\.[^.]*$)', value.__qualname__):
                value = object.__getattribute__(self, '_parent_import_replacements_callback')(value, replacements)
                break

        return value
`}</code></pre>
    <p><strong parentName="p">{`Usage`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`class Apple(Fruit, ParentImportReplacer):
    def __init__(self, *args, **kwargs):
        self.parent_import_replacements = {
            'FunctionClass': {
                'OriginalImport': ReplacementImplementation,
            }
        }
        super().__init__(*args, **kwargs)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      