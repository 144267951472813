import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Resources</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Apply</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Install CLI</AnchorLink>
  <AnchorLink mdxType="AnchorLink">References</AnchorLink>
    </AnchorLinks>
    <h2>{`Resources`}</h2>
    <h3>{`Base`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/linkerd/kustomization.yaml",
        "path": "/base/linkerd/kustomization.yaml"
      }}>{`---
apiVersion: kustomize.config.k8s.io/v1beta1
kind: Kustomization

namespace: linkerd

resources:
  - namespace.yaml

generators:
  - linkerd-crds-chart.yaml
  - linkerd-control-plane-chart.yaml
  - linkerd-viz-chart.yaml

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/linkerd/namespace.yaml",
        "path": "/base/linkerd/namespace.yaml"
      }}>{`---
apiVersion: v1
kind: Namespace

metadata:
  name: linkerd

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/linkerd/linkerd-control-plane-chart.yaml",
        "path": "/base/linkerd/linkerd-control-plane-chart.yaml"
      }}>{`---
apiVersion: builtin
kind: HelmChartInflationGenerator

name: linkerd-control-plane

metadata:
  name: linkerd-control-plane-helm-chart

repo: https://helm.linkerd.io/stable
version: 1.9.4
releaseName: linkerd-control-plane

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/linkerd/linkerd-crds-chart.yaml",
        "path": "/base/linkerd/linkerd-crds-chart.yaml"
      }}>{`---
apiVersion: builtin
kind: HelmChartInflationGenerator

name: linkerd-crds

metadata:
  name: linkerd-crds-helm-chart

repo: https://helm.linkerd.io/stable
version: 1.4.0
releaseName: linkerd-crds

IncludeCRDs: true

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/linkerd/linkerd-viz-chart.yaml",
        "path": "/base/linkerd/linkerd-viz-chart.yaml"
      }}>{`---
apiVersion: builtin
kind: HelmChartInflationGenerator

name: linkerd-viz

metadata:
  name: linkerd-viz-helm-chart

repo: https://helm.linkerd.io/stable
releaseName: linkerd-viz
version: 30.3.4

`}</code></pre>
    <h2>{`Applying`}</h2>
    <p><strong parentName="p">{`Verify system requirements`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`linkerd check --pre
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`linkerd install --crds | kubectl apply -f - \\
&& linkerd install | kubectl apply -f - \\
&& linkerd check \\
&& linkerd viz install | kubectl apply -f - \\
&& linkerd viz check
`}</code></pre>
    <h2>{`Install CLI`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl -sL https://run.linkerd.io/install | sh \\
&& export PATH=$PATH:$HOME/.linkerd2/bin
`}</code></pre>
    <h2>{`References`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://linkerd.io/2.12/tasks/gitops/"
        }}>{`Using GitOps with ArgoCD and LinkerD (LinkerD)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/linkerd/linkerd-examples/tree/master/gitops"
        }}>{`Linkerd GitOps (Github)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=Mh0Wqu3v8h0"
        }}>{`Service Mesh at Scale: How Xbox Cloud Gaming Secures 22k Pods with Linkerd - Christopher Voss
(YouTube)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/linkerd/linkerd2/issues/7345"
        }}>{`Discussion: Support loading identity trust roots from a Secret (Github)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      