import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Setup`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=docker-compose-kcachegrind.yml",
        "path": "docker-compose-kcachegrind.yml"
      }}>{`version: "3.9"

services:
  nginx-xdebug:
    image: wodby/nginx:1.19
    environment:
      NGINX_VHOST_PRESET: php
      NGINX_BACKEND_HOST: xdebug
      NGINX_SERVER_ROOT: /var/www/html/public
    ports:
      - 8000:80
    volumes:
      - app:/var/www/html
    depends_on:
      - xdebug

  xdebug:
    image: wodby/php:8.1-dev
    environment:
      PHP_DEBUG: true
      PHP_XDEBUG: true
      PHP_XDEBUG_MODE: profile
    expose:
      - 9000:9000
    volumes:
      - app:/var/www/html
      - xdebug:/mnt/files/xdebug

  kcachegrind:
    image: ghcr.io/nedix/kcachegrind-docker
    ports:
      - 8001:8080
    volumes:
      - app:/var/www/html
      - xdebug:/data/xdebug
    depends_on:
      - xdebug

networks:
  default:
    driver: bridge

volumes:
  app:
    driver: local
    driver_opts:
      device: \${PWD}
      o: bind
      type: none
  xdebug:
    driver: local
`}</code></pre>
    <h2>{`Usage`}</h2>
    <h3>{`Step 1.`}</h3>
    <p>{`Start the containers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`docker compose -f docker-compose.yml -f docker-compose-kcachegrind.yml up -d
`}</code></pre>
    <h3>{`Step 2.`}</h3>
    <p>{`Navigate to your page on `}<a parentName="p" {...{
        "href": "http://localhost:8000"
      }}>{`localhost:8000`}</a>{` to capture the profiling data.`}</p>
    <h3>{`Step 3.`}</h3>
    <p>{`Find KCachegrind on `}<a parentName="p" {...{
        "href": "http://localhost:8001"
      }}>{`localhost:8001`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      