import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Resources</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Apply</AnchorLink>
  <AnchorLink mdxType="AnchorLink">References</AnchorLink>
    </AnchorLinks>
    <h2>{`Resources`}</h2>
    <h3>{`Base`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/harbor/kustomization.yaml",
        "path": "/base/harbor/kustomization.yaml"
      }}>{`---
apiVersion: kustomize.config.k8s.io/v1beta1
kind: Kustomization

namespace: harbor

resources:
  - namespace.yaml

generators:
  - harbor-chart.yaml

generatorOptions:
  disableNameSuffixHash: true

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/harbor/namespace.yaml",
        "path": "/base/harbor/namespace.yaml"
      }}>{`---
apiVersion: v1
kind: Namespace

metadata:
  name: harbor

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/harbor/harbor-chart.yaml",
        "path": "/base/harbor/harbor-chart.yaml"
      }}>{`---
apiVersion: builtin
kind: HelmChartInflationGenerator

name: harbor

metadata:
  name: bitnami-sealed-secrets-helm-chart

repo: https://charts.bitnami.com/bitnami
releaseName: harbor
version: 16.0.0

valuesFile: values.yaml
IncludeCRDs: true

`}</code></pre>
    <p><strong parentName="p">{`Create values.yaml`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`helm repo add bitnami https://charts.bitnami.com/bitnami \\
&& helm show values --version 15.2.5 bitnami/harbor > values.yaml
`}</code></pre>
    <h2>{`Applying`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`kubectl kustomize --enable-helm | kubectl apply -f - \\
&& kubectl wait -n harbor --for=condition=available deployments/harbor-release-nginx --timeout=-1s \\
&& echo Username: "admin" \\
&& echo Password: \`kubectl get secret -n harbor harbor-release-core-envvars -o jsonpath="{.data.HARBOR_ADMIN_PASSWORD}" | base64 -d\` \\
&& kubectl -n harbor port-forward deployment/harbor-release-nginx :8443
`}</code></pre>
    <h2>{`References`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goharbor.io/"
        }}>{`Website`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/bitnami/containers/tree/main/bitnami/harbor-registry"
        }}>{`Repository (Github)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      