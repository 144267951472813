import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Update mismatched packages`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm update --force --all
`}</code></pre>
    <h2>{`Generate package-lock.json from yarn.lock`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`echo "nodeLinker: node-modules" >> .yarnrc.yml \\
&& rm -rf node_modules \\
&& npm exec yarn \\
&& npm exec synp -- --source-file yarn.lock \\
&& rm yarn.lock .yarnrc.yml \\
&& npm clean-install \\
&& npm dedupe --legacy-peer-deps \\
&& npm prune --legacy-peer-deps
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      