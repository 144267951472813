import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-lua"
      }}>{`-- Classes
Candy = {}
function Candy:new(name, price)
    local object = {name = name, price = price}
    setmetatable(object, { __index = Candy })
    return object
end

-- Entry point
function main()
    -- Variables and data types
    local candy_shop_name = "Sweet Tooth"
    local is_open = true
    local candy_names = {"chocolate", "gummy bears", "lollipops"}
    local candy_prices = {2.50, 1.50, 0.50}
    local inventory = {}

    -- Conditional statements
    if is_open then
        print(candy_shop_name .. " is open!")
    else
        print(candy_shop_name .. " is closed.")
    end

    -- Loops
    for i = 1, #candy_names do
        local candy = Candy:new(candy_names[i], candy_prices[i])
        table.insert(inventory, candy)
    end

    -- Functions
    local function calculate_total_price(candies)
        local total_price = 0
        for i = 1, #candies do
            total_price = total_price + candies[i].price
        end
        return total_price
    end

    -- Exception handling
    local function buy_candy(candy_name)
        for i = 1, #inventory do
            if inventory[i].name == candy_name then
                table.remove(inventory, i)
                return "You bought a " .. candy_name .. " for " .. inventory[i].price .. " dollars."
            end
        end
        error("Sorry, we are out of " .. candy_name .. "s.")
    end

    -- Using functions
    local total_price = calculate_total_price(inventory)
    print("The total price of the candy in the inventory is " .. total_price .. " dollars.")

    -- Exception handling
    print(buy_candy("chocolate"))
    print(buy_candy("gummy bears"))
    print(buy_candy("lollipops"))
    print(pcall(buy_candy, "jawbreakers"))
end

-- Creating objects
chocolate = Candy:new("chocolate", 2.50)
gummy_bears = Candy:new("gummy bears", 1.50)
lollipops = Candy:new("lollipops", 0.50)

-- Using object's properties
print(chocolate.name)
print(gummy_bears.price)

if debug.getinfo(1).source:sub(1,1) == "@" then
    main()
end
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      