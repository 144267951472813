import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Composer`}</h2>
    <p><strong parentName="p">{`Run without a memory limit`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`php -d memory_limit=-1 $(which composer) <your arguments...>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      