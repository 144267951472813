import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Tools`}</h2>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
  <ImageCard title="KCachegrind" actionIcon="arrowRight" href="kcachegrind" aspectRatio="4:3" titleColor="dark" iconColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "57.638888888888886%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAADNElEQVQozyWRW2xTBRzG+86DMeoDifH6ZHzw1UQuiYgKLhqMhgc0iEZ9WLJkmk02iDIhCCOL27QyszE2CQgKG7AgDVvL5sbYfZ3rWdvTc21Pz6Xn9PSsI1Nx+DOt/+TL9/bL9/2/UDyVoyJBtklkTKaXJKbjGe4upFjO6KR1i6Sar0qQK+6wohYQlAJJzSell0hpPumK6y4hw3LIWRZmwcXxA/S8SUpWESWF0dgC42MCkxNJpiZFluJpVFlHk7PkNJ1sTkDU55CMBUR1DlWSCU2sGIwmZG7NpxhbMYgtydxJW4hWiZpXYzy7Ocrzz9zmuSeG+Xh/jM9qPRrqTNqOSYRPd/DKy79R8/oou944w+Gmq4Qcz8G1CyQTCfJZjZymUrDyrJXL7Nga47FNUTY/FOXJRyMc2DvB2zvho/dKHG8bpL3ra7ZtOcvWLRfYvqOVpoZrhMrBKrZrEF9aZDkhIEkypmUSBGVee2maxzdN8fQjUzz18Ay1nw7y1ckhvg1f4kKkg/C5k7z74SH27D/CO5/Uc6hxmFBQCrC8AmlRxDRNDMOoulf0qPkgzAu729i2q50Xt5+g7sswnT2DHO3q5lT3Txw83s3u95vYufcIb9Xv4XDLCULFokfedkkIK0iShCzL2LbN6mpAe38rzd810vDNPpqOvUnXmRYuDZ7n4tAvXB8ZZOD2FbrO99L7cx/9A+1EbvUS8os+puvwR2IZRVFQVbUKXF9fJz6X4drlcTo6W/mx+xQDV+4yNSmhiDa++yfra/9SuY2/7sMG3P/7ASHf9ykWHRLCMhkxg6aqVWjBsTl39At6Gg7Q1x7m4q8jRG7eYK6vmakfalnq+xzhbD3m8u9EIqNEr0/jGBahUhDguw5SJoOqamSz2WrSSsqxsWHujEdZmJ8lNppgeGScxdlJFmdmKHs5bg4JRKM6WWeBjJLFc1f/r1wZJZlKomla9Y+KrGAVLBov11J3dR+dN/ppOS3Q/P0sLT1xDobnkY2AfzbALz1ASBsk0g5BsEaoVCphew5pMY0sSei6XgW7rksys0JSTiBpCkJKISWqyFoeRbfwvCL37pVZWytj5n0su1Rt9R+lSwjEfysBPwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "KCachegrind",
              "title": "KCachegrind",
              "src": "/static/79a885d31b907164ea94aacaf5c6a3c5/3cbba/kcachegrind.png",
              "srcSet": ["/static/79a885d31b907164ea94aacaf5c6a3c5/7fc1e/kcachegrind.png 288w", "/static/79a885d31b907164ea94aacaf5c6a3c5/a5df1/kcachegrind.png 576w", "/static/79a885d31b907164ea94aacaf5c6a3c5/3cbba/kcachegrind.png 1152w", "/static/79a885d31b907164ea94aacaf5c6a3c5/0b124/kcachegrind.png 1728w", "/static/79a885d31b907164ea94aacaf5c6a3c5/aec32/kcachegrind.png 1871w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
  <ImageCard title="SPX" actionIcon="arrowRight" href="spx" aspectRatio="4:3" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSElEQVQoz03Ez2vTUBwA8O97eemSNE3ymvblJWnS/Cp1689k7SxjWFHE49RNYTj8BYI6B14U5mFDHY4pXhTBiwdPehIPHhUmbqCIsJsOD568+jdIxw7Chw90w2Q89BusktrVDgvaLE7tWttKJkphy0oaLKmXo9RLJv2o41YzP+oFceoFmVfNPA9WLtRe3g5e36m+vxt8XIu2VsOt1fDTWrhzL9q+H22vx9sPoqeLE+uz3Y359OFsd/N05/F859Fcd3MuA9TvslMTzSdp682g/2Hq3Ofe+a+9s9+mz+weufxzuLQ3vP5rqB/LIM5Qowf1FA5lB8YnQeYx0aoCT0jWEo+n2vxk4WKaW+rCRkt51uTP296Lbi6qihoXi7ZYdEaoPWK6YEU13eJysSRTJmuWpFpEMolSHLOYWHGIw7FbInqRaEVxhIoaJQVKNEp0E5ATYjcaXYmw7WPLwcwWuCe4oVCJkRMZ9Xau5AhGmVBLMBj+D+BaEydNHDewnyDmIpMjylCRIzfAXgTcDwYzKnNA0pBiiAVKCgZWKVYpUingsI6DGvJjxCtQoAd0E1ku5h6Ydtif1kwrKKtXBqVXC/agRoGoWCngvAa4GiMvxG6AyhzyGiiFkbyOTAszB2jJyw6fjNnbE8aXRevPTXu5ZwCM6ZKEkATYC5DrI8cHswyyApIMYzJICqImNhmoRmeq//1auHeD/1h2/654v29Vdi7x3av2uwULIJcDcZ9AAOMRtL9AgBDAQl5V2p7cdnM9XxoE0kwsHU3k4b5/zFdi7FF/6VgAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "SPX",
              "title": "SPX",
              "src": "/static/50b8a71a4bad7372c88235ed3559cc34/3cbba/spx.png",
              "srcSet": ["/static/50b8a71a4bad7372c88235ed3559cc34/7fc1e/spx.png 288w", "/static/50b8a71a4bad7372c88235ed3559cc34/a5df1/spx.png 576w", "/static/50b8a71a4bad7372c88235ed3559cc34/3cbba/spx.png 1152w", "/static/50b8a71a4bad7372c88235ed3559cc34/0b124/spx.png 1728w", "/static/50b8a71a4bad7372c88235ed3559cc34/e957c/spx.png 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
  <ImageCard title="XHgui" actionIcon="arrowRight" href="spx" aspectRatio="4:3" titleColor="dark" iconColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "52.083333333333336%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABVklEQVQoz41STUvEMBDtj/OP+Cv0JK5nPSj9A+LZi3cPguLBRWFhQQTXdrekbbZtmvQjbZrMSBqsK3rwncKbeXkvM/HiJI0pZazkvASAbLulaVqWJe4AAMAYd0BEKWXbtlJKL0kpzfKRtDVRVYyxpm2VAWNFttArJXullDLGcnqEUspjrOCc65FFxCxaJ5tNpwY0enKuBY9elyVjiIDOB9EY46WUciGs7RiseH4ITg+66MMlca1tkdHbG/wJK06SJAxDKaXqO+s8v1/s7639Y/50B6p3fVKUq+vLfDHv1u+6Ft/iPM8JIU3TtE0DiOJtGfgnwfnR6uxQt7XrG5SKri5Cfxb4M/7yiC6nc04pdQMc1FCwcvh61YSqqqqm+SM2IWRaDABsKbX5AdDtZuQZY0kcj9MyCDviOI7zPJ/uE0LUv0y6rquq+s+BpYTEWuvpD/wTwzB8AphkOzhi7U+mAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "XHgui",
              "title": "XHgui",
              "src": "/static/7a618fac405ed92f5c34f13810618e9c/3cbba/xhgui.png",
              "srcSet": ["/static/7a618fac405ed92f5c34f13810618e9c/7fc1e/xhgui.png 288w", "/static/7a618fac405ed92f5c34f13810618e9c/a5df1/xhgui.png 576w", "/static/7a618fac405ed92f5c34f13810618e9c/3cbba/xhgui.png 1152w", "/static/7a618fac405ed92f5c34f13810618e9c/7dc98/xhgui.png 1200w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      