import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Resources</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Apply</AnchorLink>
  <AnchorLink mdxType="AnchorLink">References</AnchorLink>
    </AnchorLinks>
    <h2>{`Resources`}</h2>
    <h3>{`Base`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/cert-manager/kustomization.yaml",
        "path": "/base/cert-manager/kustomization.yaml"
      }}>{`---
apiVersion: kustomize.config.k8s.io/v1beta1
kind: Kustomization

namespace: cert-manager

resources:
  - namespace.yaml

generators:
  - cert-manager-chart.yaml

generatorOptions:
  disableNameSuffixHash: true

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/cert-manager/namespace.yaml",
        "path": "/base/cert-manager/namespace.yaml"
      }}>{`---
apiVersion: v1
kind: Namespace

metadata:
  name: cert-manager

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/cert-manager/cert-manager-chart.yaml",
        "path": "/base/cert-manager/cert-manager-chart.yaml"
      }}>{`---
apiVersion: builtin
kind: HelmChartInflationGenerator

namespace: cert-manager
name: cert-manager

metadata:
  name: cert-manager-helm-chart

repo: https://charts.jetstack.io
version: v1.10.0
releaseName: cert-manager
IncludeCRDs: true

`}</code></pre>
    <h2>{`Applying`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`kubectl apply -f https://github.com/cert-manager/cert-manager/releases/download/v1.9.1/cert-manager.crds.yaml \\
&& kubectl apply -f https://github.com/cert-manager/cert-manager/releases/download/v1.9.1/cert-manager.yaml
`}</code></pre>
    <h2>{`References`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.padok.fr/en/blog/traefik-kubernetes-certmanager"
        }}>{`Use Cert-manager to manage certificates in your cluster (Padok)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://vrelevant.net/crossplane-packages-self-signed-registry-cert/"
        }}>{`Crossplane Packages – Self-signed Registry Cert (vRelevant)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cert-manager.io/docs/projects/trust-manager/"
        }}>{`trust-manager (cert-manager)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      