import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Resources</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Apply</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Install CLI</AnchorLink>
  <AnchorLink mdxType="AnchorLink">References</AnchorLink>
    </AnchorLinks>
    <h2>{`Resources`}</h2>
    <h3>{`Base`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/sealed-secrets/kustomization.yaml",
        "path": "/base/sealed-secrets/kustomization.yaml"
      }}>{`---
apiVersion: kustomize.config.k8s.io/v1beta1
kind: Kustomization

namespace: sealed-secrets

resources:
  - namespace.yaml
  - https://github.com/bitnami-labs/sealed-secrets/releases/download/v0.18.5/controller.yaml

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/sealed-secrets/namespace.yaml",
        "path": "/base/sealed-secrets/namespace.yaml"
      }}>{`---
apiVersion: v1
kind: Namespace

metadata:
  name: sealed-secrets

`}</code></pre>
    <h2>{`Applying`}</h2>
    <p><strong parentName="p">{`Apply`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`wget https://github.com/bitnami-labs/sealed-secrets/releases/download/v0.18.5/controller.yaml \\
&& kubectl apply -f controller.yaml
`}</code></pre>
    <p>{`Check status of the controller pod.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`kubectl get pods -n kube-system | grep sealed-secrets-controller
`}</code></pre>
    <p><em parentName="p">{`The Sealed Secret CRD, controller and RBAC artifacts should be installed in the `}<strong parentName="em">{`kube-system`}</strong>{` namespace.`}</em></p>
    <h2>{`Install CLI`}</h2>
    <p><strong parentName="p">{`Darwin`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`brew install kubeseal
`}</code></pre>
    <p><strong parentName="p">{`Linux`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`wget https://github.com/bitnami-labs/sealed-secrets/releases/download/v0.18.5/kubeseal-0.18.5-linux-amd64.tar.gz \\
&& tar xfz kubeseal-0.18.5-linux-amd64.tar.gz \\
&& sudo install -m 755 kubeseal /usr/local/bin/kubeseal
`}</code></pre>
    <h2>{`References`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cloud.redhat.com/blog/a-guide-to-secrets-management-with-gitops-and-kubernetes"
        }}>{`A Guide to Secrets Management with GitOps and Kubernetes (RedHat)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.eksworkshop.com/beginner/200_secrets/installing-sealed-secrets/"
        }}>{`Installing Sealed Secrets (Amazon)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stackoverflow.com/questions/73646234/argocd-with-sealed-secrets-and-kustomize"
        }}>{`ArgoCD with Sealed Secrets and Kustomize (Stack overflow)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://faun.pub/sealing-secrets-with-kustomize-51d1b79105d8"
        }}>{`Sealing Secrets with Kustomize (Medium)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      