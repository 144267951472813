import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`GIT tags data source`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json5",
        "metastring": "path=.github/renovate.json5",
        "path": ".github/renovate.json5"
      }}>{`{
  regexManagers: [
    {
      fileMatch: ["^Dockerfile$"],
      matchStrings: ["ARG DEPENDENCY_VERSION=(?<currentValue>.*?)\\\\n"],
      datasourceTemplate: "git-tags",
      depNameTemplate: "https://github.com/<ORGANIZATION>/<PROJECT>",
      versioningTemplate: "semver-coerced",
    },
  ],
}
`}</code></pre>
    <p><strong parentName="p">{`GitHub tags data source`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json5",
        "metastring": "path=.github/renovate.json5",
        "path": ".github/renovate.json5"
      }}>{`{
  regexManagers: [
    {
      fileMatch: ["^Dockerfile$"],
      matchStrings: ["ARG DEPENDENCY_VERSION=(?<currentValue>.*?)\\\\n"],
      datasourceTemplate: "github-tags",
      depNameTemplate: "<ORGANIZATION>/<PROJECT>",
      versioningTemplate: "semver-coerced",
    },
  ],
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      