import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Connecting a physical drive`}</h2>
    <p>{`This applies to Windows installations.`}</p>
    <p><strong parentName="p">{`List partitions`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`vboxmanage internalcommands listpartitions -rawdisk \\\\.\\PhysicalDrive0
`}</code></pre>
    <p><strong parentName="p">{`Create the disk file`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`vboxmanage internalcommands createrawvmdk -filename "C:\\Users\\user\\VirtualBox VMs\\Fedora\\RawDisk.vmdk" -rawdisk \\\\.\\PhysicalDrive0 -partitions 4,5
`}</code></pre>
    <p>{`Import the disk file into the virtual machine`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      