import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/project/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`View`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-twig"
      }}>{`<!DOCTYPE html>
<html>
    <head>
        <title>{{ title }}</title>
    </head>
    <body>
        <h1>{{ header }}</h1>

        <div class="content">
            {% if content %}
                <p>{{ content }}</p>
            {% else %}
                <p>No content available.</p>
            {% endif %}
        </div>

        <ul>
            {% for item in listItems %}
                <li>{{ item }}</li>
            {% endfor %}
        </ul>

        <form method="post" action="{{ actionUrl }}">
            <input type="hidden" name="_token" value="{{ csrf_token() }}" />
            <label for="username">Username:</label>
            <input type="text" id="username" name="username" value="{{ username }}" />
            <br />
            <label for="password">Password:</label>
            <input type="password" id="password" name="password" value="{{ password }}" />
            <br />
            <button type="submit">Submit</button>
        </form>

        {% if showDebugInfo %}
            <pre>
                {{ dump(model) }}
            </pre>
        {% endif %}
    </body>
</html>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      